.dock-panel {
  background: #eaeaea;

  /* Grey / Mid Grey */
  border: 2px solid #bbbbbb;
  border-radius: 4px;
}
.dock-panel.active-panel {
  border: 2px solid red !important;
}
.dock-panel.active-panel .dock .dock-bar,
.dock-panel.active-panel
  .dock
  .dock-bar
  .dock-nav
  .dock-nav-wrap
  .dock-nav-list
  .dock-tab {
  background-color: #fff7f7 !important;
}
.dock-extra-content {
  display: none;
}

.dock-nav-list,
.dock-tab-btn,
.dock-tab {
  width: 100%;
}

.dock-tab-btn {
  text-align: left;
  padding-left: 0 !important;
}
.dock-tab-close-btn {
  color: black;
}

.dock-tab {
  border-bottom: none;
}

.dock-ink-bar {
  display: none;
}

.dock-tab-active,
.dock-tab-active:hover {
  color: black;
}
