.highlight {
  background-color: #f8fb63;
  font-size: inherit;
}

.font-bold,
.font-bold > * {
  font-weight: 700;
}

.italic,
.italic > * {
  font-style: italic;
}
